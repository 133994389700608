import React from 'react';
import { observer } from "mobx-react-lite";
import 'rsuite/dist/styles/rsuite-dark.css';
import './App.scss';
import Bio from './Bio';
import ThemeGallery from './ThemeGallery';
import ThemeWasSwitchedToPopup from './ThemeWasSwitchedToPopup';
import Toolbar from './Toolbar';
import {ThemeProperties, overriddenThemeActive} from './Stores';
import IntroTour from './IntroTour';
import QualityCheck from './QualityCheck';
import DonePopup from './DonePopup';
import ChangeURLPopup from './ChangeURLPopup';
import CloneIntroPopup from './CloneIntroPopup';

const App = observer(({bioStore, interfaceStateStore, serverSynchronization}) => {
	let bios = null;
	if(interfaceStateStore.themeGalleryOpen) {
		bios = <ThemeGallery bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></ThemeGallery>;
	} else {
		const theme = interfaceStateStore.previewingTheme || interfaceStateStore.shoppingTheme || interfaceStateStore.myBioWithTheme || bioStore.theme;
		let isOverriddenTheme;
		if(interfaceStateStore.previewingTheme) {
			isOverriddenTheme = interfaceStateStore.previewingThemeIsOverriddenTheme;	
		} else {
			isOverriddenTheme = overriddenThemeActive(bioStore, interfaceStateStore);
		}
		const themeProperties = ThemeProperties[theme];
		let displayMode;
		if(interfaceStateStore.previewingForShare) {
			displayMode = "viewing";
		} else if(interfaceStateStore.previewingTheme || interfaceStateStore.shoppingTheme || interfaceStateStore.myBioWithTheme) {
			displayMode = "previewing";
		} else {
			displayMode = interfaceStateStore.isEditing ? "editing" : "viewing";
		}

		bios = <Bio bioStore={bioStore} interfaceStateStore={interfaceStateStore} theme={theme} isOverriddenTheme={isOverriddenTheme} themeProperties={themeProperties} displayMode={displayMode} serverSynchronization={serverSynchronization}></Bio>;
	}
	
	let introPopup = null;
	let donePopup = null;
	let changeURLPopup = null;
	if(interfaceStateStore.isEditing) {
		if(interfaceStateStore.firstRun) {
			if(interfaceStateStore.introState === "clone") { // We were cloned, and so should show the alternate intro popup
				introPopup = <CloneIntroPopup interfaceStateStore={interfaceStateStore} bioStore={bioStore}></CloneIntroPopup>;
			} else if(interfaceStateStore.introState === "normal") {
				introPopup = <IntroTour interfaceStateStore={interfaceStateStore}></IntroTour>
			}
		} else if(interfaceStateStore.themeWasSwitchedTo) {
			introPopup = <ThemeWasSwitchedToPopup></ThemeWasSwitchedToPopup>;
		}
		donePopup = <DonePopup bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></DonePopup>;
		changeURLPopup = <ChangeURLPopup bioStore={bioStore} interfaceStateStore={interfaceStateStore} serverSynchronization={serverSynchronization}></ChangeURLPopup>;
	}
	
	return (
		<React.Fragment>
			{introPopup}
			{donePopup}
			{changeURLPopup}
			{bios}
			<QualityCheck bioStore={bioStore} interfaceStateStore={interfaceStateStore}></QualityCheck>
			<Toolbar bioStore={bioStore} interfaceStateStore={interfaceStateStore}></Toolbar>
		</React.Fragment>	
	);
});

export default App;
