import React from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Input } from 'rsuite';

const ChangeURLPopup = observer(({
	bioStore,
	interfaceStateStore,
	serverSynchronization
}) => {
	function onDone() {
		interfaceStateStore.setDoneScreenPhase("none");
	}
	
	function setSelectedNicename(value) {
		const filteredValue = value.replace(/[^a-zA-Z0-9-]/g, ''); // Filter out everything but alphanumeric/hyphens for their URLs
		interfaceStateStore.setSelectedNicename(filteredValue);
	}

	const showPanel = (interfaceStateStore.doneScreenPhase === "changeURL" && interfaceStateStore.hasSetURL && !interfaceStateStore.changeURLComplete);

	const publishFields = (<>
		<div className="edit-panel-field">
			<label className="done-field-label">1. <strong>Select your preferred domain:</strong> Choose from the available domain options in the dropdown.</label>	
			<select
				value={interfaceStateStore.selectedDomain}
				onChange={evt => interfaceStateStore.setSelectedDomain(evt.target.value)}
			>
				{interfaceStateStore.availableDomains.map(domain =>
					<option value={domain}>{domain}</option>
				)};
			</select>
		</div>
		<div className="edit-panel-field">
		<label className="done-field-label">2. <strong>Change your username:</strong> You may modify the username portion of your link, if the new username is available.</label>	
			<Input 
				value={interfaceStateStore.selectedNicename}
				onChange={setSelectedNicename}
			/>
		</div>	
		<p><strong>Note:</strong> If you’ve only changed your domain, your QR code and previous links will still work. However, if you changed your username, be sure to update your link everywhere you’ve saved it, and don’t forget to update your QR code to ensure it links to your new ModLink.</p>
	</>);
	const publishButton = (<button
		onClick={() => serverSynchronization.setBioURL(interfaceStateStore.selectedDomain, interfaceStateStore.selectedNicename, true)}
	>
		Save
	</button>);
		
	return (
		<Modal show={showPanel} className="edit-panel done-popup-panel" overflow={false}>
			<Modal.Body className="edit-panel-body">
				<button onClick={onDone} className="done-close-button">Close <i className="fa-solid fa-xmark"></i></button>
				<h1>Edit your Link</h1>
				<p>Are you looking to change your ModLink domain and/or username? Easily customize your ModLink URL below: </p>
				{ publishFields }
				<p className="done-body-description">Your link: <span className="done-link-preview">{interfaceStateStore.selectedDomain}/{interfaceStateStore.selectedNicename}</span></p>
				{ publishButton }
			</Modal.Body>
		</Modal>
	)
});
 
export default ChangeURLPopup;
